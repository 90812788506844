import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { resolveAsAttachment } from '@algbra/ui/resolvers'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { map } from 'rambdax'
import EarlyAccess from '../../components/EarlyAccess/EarlyAccess'
import { FooterStoreLinks } from '../../types/footer'
import styles from './InlineWaitlist.module.scss'

export type ZoneInlineWaitlistProps = {
  __typename: 'StrapiGQL_ComponentZonesInlineWaitlist'
  title: string
}

export const QUERY_WAITLISTBANNER = graphql`
  query {
    strapiGQL {
      waitlistBanner {
        title
        subtitle
        id
        storeLinks {
          id
          url
          alternativeText
          image {
            id
            url
            ext
            width
            height
            resolved {
              publicURL
            }
          }
        }
      }
    }
  }
`
export default function ZoneInlineWaitlist(
  props: ZoneInlineWaitlistProps
): JSX.Element {
  const {
    strapiGQL: { waitlistBanner },
  } = useStaticQuery(QUERY_WAITLISTBANNER)
  const { title, subtitle, storeLinks } = waitlistBanner

  return (
    <Whitespace type="md" className={styles.whitespace} hasAnimation={false}>
      <div className={styles.container} id="earlyaccess">
        <Container fluid={false} className={styles.contentContainer}>
          <div className={styles.card}>
            <StaticImage
              src="../../images/card-waitlist.png"
              alt="Algbra Card"
              layout="constrained"
              width={566}
              quality={100}
              placeholder="blurred"
            />
          </div>
          <div className={styles.frame}>
            <Heading level={2} type="xl" className={styles.title}>
              {title}
            </Heading>
            {subtitle && <p className={styles.text}>{subtitle}</p>}

            <div className={styles.storeLinksContainer}>
              {map(({ image, url, alternativeText }) => {
                return (
                  <a key={`store-link-${image.id}`} href={url}>
                    <img
                      src={resolveAsAttachment(image)}
                      alt={alternativeText}
                      width={image.width}
                      height={image.height}
                      loading="lazy"
                    />
                  </a>
                )
              }, storeLinks as FooterStoreLinks[])}
            </div>

            {/* <EarlyAccess identifier="bannerInlineWaitlist" /> */}
          </div>
        </Container>
      </div>
    </Whitespace>
  )
}
