import { FormInputEventProps } from '../../types/events'
import {
  FormElementStyleProps,
  FormInputProps,
} from '../../types/forms'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import Label from '../Label/Label'
import ValidationError from '../ValidationError/ValidationError'
import styles from './Input.module.scss'

export type InputProps = {
  single?: boolean
  type?:
    | 'email'
    | 'text'
    | 'url'
    | 'number'
    | 'password'
    | 'search'
    | 'hidden'
    | 'tel'
    | 'date'
    | 'datetime'
  prefix?: ReactNode
  suffix?: ReactNode
} & FormInputProps &
  FormInputEventProps<HTMLInputElement> &
  FormElementStyleProps

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    type,
    size = 'normal',
    single = false,
    className,
    controlClassName,
    prefix,
    suffix,
    error,
    ...inputProps
  } = props
  return (
    <div
      className={classNames(
        styles.container,
        [styles[size]],
        [styles[`type__${type}`]],
        { [styles.single]: single },
        className
      )}
    >
      {label && <Label className={styles.label}>{label}</Label>}
      <div className={classNames(styles.element)}>
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <input
          {...inputProps}
          type={type}
          ref={ref}
          className={classNames(
            styles.control,
            {
              [styles.prefixed]: !!prefix,
              [styles.suffixed]: !!suffix,
              [styles.error]: !!error,
            },
            controlClassName
          )}
        />
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </div>
      {error && <ValidationError message={error.message} />}
    </div>
  )
})
Input.displayName = 'Input'
export default Input
