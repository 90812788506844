import Button from '@algbra/ui/components/Button/Button'
import Checkbox from '@algbra/ui/components/Checkbox/Checkbox'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import Input from '@algbra/ui/components/Input/Input'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import {
  MEDIA_QUERY_MOBILE,
  useMediaQuery
} from '@algbra/ui/hooks/useMediaQuery'
import { getIconByName } from '@algbra/ui/icons'
import { graphql, useStaticQuery } from 'gatsby'
import {
  filter,
  includes,
  isEmpty,
  isNil,
  length,
  map,
  pluck,
  propEq,
  reject, toLower,
  uniq
} from 'rambdax'
import { useState } from 'react'
import ContentBox from '../../components/ContentBox/ContentBox'
import { Job } from '../../components/Job/Job'
import styles from './CareersJobs.module.scss'

export type WorkableJob = {
  id: string
  title: string
  department: string
  employment_type: string
  application_url: string
  url: string
  location: {
    city: string
    country: string
  }
}
export const QUERY_WORKABLE = graphql`
  query WorkableJobs {
    allWorkableJob {
      nodes {
        id
        title
        department
        employment_type
        url
        application_url
        location {
          city
          country
        }
      }
    }
  }
`
export type ZoneCareersJobsProps = {
  __typename: 'StrapiGQL_ComponentZonesCareersJobs'
  id: string
  title: string
}

export function getJobCountByField(field: string, jobs: WorkableJob[]) {
  return value => length(filter(job => propEq(field, value, job), jobs))
}

export function ZoneCareersJobs(props: ZoneCareersJobsProps) {
  const { title } = props
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
  const [isFilterVisible, setFilterVisibility] = useState(!isMobile)
  const [filters, setFilter] = useState<{
    q?: string
    jobType?: string[]
    teams?: string[]
  }>({ q: '', teams: [], jobType: [] })
  const {
    allWorkableJob: { nodes },
  } = useStaticQuery(QUERY_WORKABLE)

  const jobs: WorkableJob[] = nodes
  const jobsSearched: WorkableJob[] = filter(job => {
    if (filters?.q) {
      return !!includes(toLower(filters.q), toLower(job.title))
    }
    return true
  }, nodes)
  const jobTypes = reject(v => isNil(v), uniq(pluck('employment_type', jobs)))
  const teams = reject(v => isNil(v), uniq(pluck('department', jobs)))

  const countByTeam = getJobCountByField('department', jobsSearched)
  const filteredJobs = filter(job => {
    const teamMatch =
      !isNil(filters.teams) && !isEmpty(filters.teams)
        ? !!includes(job.department, filters.teams)
        : true
    const jobTypeMatch =
      !isNil(filters.jobType) && !isEmpty(filters.jobType)
        ? !!includes(job.employment_type, filters.jobType)
        : true
    return jobTypeMatch && teamMatch
  }, jobsSearched || [])

  const checkFilter = (isChecked, value, key) => {
    const newValue = !isChecked
      ? reject(selected => selected === value, filters[key])
      : uniq([...filters[key], value])
    setFilter({ ...filters, [key]: newValue })
  }

  const isNullOrEmpty = v => isEmpty(v) || isNil(v)
  const selectedJobTeams =
    length(filters.teams) > 0 ? filters.teams.join(',') : 'all teams'
  const selectedJobTypes =
    length(filters.jobType) > 0 ? filters.jobType.join(',') : 'all job types'

  const clearFilters = () => setFilter({ q: '', jobType: [], teams: [] })

  const hasFilter =
    !isNullOrEmpty(filters.q) ||
    !isNullOrEmpty(filters.jobType) ||
    !isNullOrEmpty(filters.teams)

  return (
    <Whitespace id="jobs" className={styles.jobsContainer}>
      <ContentBox size="small">
        <Heading type="lg" className={styles.heading}>
          {title}
        </Heading>
      </ContentBox>
      <Whitespace hasAnimation={false} as="div" type="lg">
        <Container>
          <Row>
            <Column size={12} md={4} className={styles.filterCol}>
              {isMobile && (
                <Button
                  theme="primary"
                  className={styles.toggleFilter}
                  block={true}
                  onClick={() => setFilterVisibility(!isFilterVisible)}
                >
                  Toggle Filters
                </Button>
              )}
              {isFilterVisible && (
                <div>
                  <div className={styles.inputWrapper}>
                    <Icon icon={getIconByName('search')} />
                    <Input
                      type="text"
                      name="keyword"
                      value={filters.q}
                      placeholder="Search by role or keyword"
                      className={styles.input}
                      controlClassName={styles.inputControl}
                      onChange={v =>
                        setFilter({ ...filters, q: v.currentTarget.value })
                      }
                    />
                  </div>

                  <fieldset className={styles.fieldset}>
                    <legend>Job Types</legend>
                    {map(
                      (type: string) => (
                        <Checkbox
                          name="jobType"
                          key={`type-${type}`}
                          checked={includes(type, filters.jobType)}
                          onChange={isChecked =>
                            checkFilter(isChecked, type, 'jobType')
                          }
                        >
                          {type}
                        </Checkbox>
                      ),
                      (jobTypes || []).sort()
                    )}
                  </fieldset>

                  <fieldset className={styles.fieldset}>
                    <legend>Teams</legend>
                    {map(
                      (team: string) => (
                        <Checkbox
                          name="teams"
                          key={`team-${team}`}
                          checked={includes(team, filters.teams)}
                          onChange={isChecked =>
                            checkFilter(isChecked, team, 'teams')
                          }
                        >
                          {team}
                          <span className={styles.count}>
                            {countByTeam(team)}
                          </span>
                        </Checkbox>
                      ),
                      (teams || []).sort()
                    )}
                  </fieldset>
                </div>
              )}
            </Column>
            <Column size={12} md={8} className={styles.contentCol}>
              <div className={styles.filters}>
                <div className={styles.filterContent}>
                  <strong>{length(filteredJobs)}</strong> job(s) in{' '}
                  <strong>{selectedJobTeams}</strong> for{' '}
                  <strong>{selectedJobTypes}</strong>
                </div>
                {hasFilter && (
                  <div className={styles.clear}>
                    <button className={styles.clearBtn} onClick={clearFilters}>
                      Clear all filters
                      <Icon
                        icon={getIconByName('times')}
                        className={styles.clearIcon}
                        size={20}
                      />
                    </button>
                  </div>
                )}
              </div>
              {map(
                ({ title, id, location, employment_type, url }) => (
                  <Job
                    key={id}
                    title={title}
                    location={location.city}
                    type={employment_type}
                    url={url}
                  />
                ),
                filteredJobs
              )}
            </Column>
          </Row>
        </Container>
      </Whitespace>
    </Whitespace>
  )
}
