import { WindowLocation } from '@reach/router'
import { mapIndexed } from 'rambdax'
import {
  ZoneCareersBenefits,
  ZoneCareersBenefitsProps,
} from './CareersBenefits/CareersBenefits'
import {
  ZoneCareersFutureTeam,
  ZoneCareersFutureTeamProps,
} from './CareersFutureTeam/CareersFutureTeam'
import {
  ZoneCareersJobs,
  ZoneCareersJobsProps,
} from './CareersJobs/CareersJobs'
import {
  ZoneCareersMission,
  ZoneCareersMissionProps,
} from './CareersMission/CareersMission'
import ZoneInlineWaitlist, {
  ZoneInlineWaitlistProps,
} from './InlineWaitlist/InlineWaitlist'

export type ZoneItemType =
  | ZoneInlineWaitlistProps
  | ZoneCareersBenefitsProps
  | ZoneCareersMissionProps
  | ZoneCareersFutureTeamProps
  | ZoneCareersJobsProps

export type CareersZoneRendererProps = {
  zones: ZoneItemType[]
}

export function getComponentByZoneType(
  zone: ZoneItemType,
  key: string,
  location?: WindowLocation<{ value: string }>
) {
  switch (zone.__typename) {
    case 'StrapiGQL_ComponentZonesInlineWaitlist':
      return <ZoneInlineWaitlist key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesCareersBenefits':
      return <ZoneCareersBenefits key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesCareersMission':
      return <ZoneCareersMission key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesCareersFutureTeam':
      return <ZoneCareersFutureTeam key={key} {...zone} />
    case 'StrapiGQL_ComponentZonesCareersJobs':
      return <ZoneCareersJobs key={key} {...zone} />
    default:
      return null
  }
}
export default function CareersZoneRenderer({
  zones,
}: CareersZoneRendererProps) {
  return (
    <main>
      {mapIndexed(
        (zone, index) =>
          getComponentByZoneType(zone, `${zone.__typename}-${index}`),
        zones
      )}
    </main>
  )
}
