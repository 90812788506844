import React, { ReactNode } from 'react'
import styles from './Checkbox.module.scss'

export type CheckboxProps = {
  name: string
  children: ReactNode
  checked?: boolean
  onChange: (checked?: boolean) => void
}
export default function Checkbox(props: CheckboxProps) {
  const { name, children, checked, onChange: onCheckboxClick } = props
  return (
    <label className={styles.container}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={() => onCheckboxClick(!checked)}
      />
      <span className={styles.check}></span>
      <div className={styles.children}>{children}</div>
    </label>
  )
}
