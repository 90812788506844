import Button from '@algbra/ui/components/Button/Button'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { graphql, Link, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PageLayout from '../components/PageLayout/PageLayout'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'
import CareersZoneRenderer from '../zones/CareersContentZone'
import styles from './careers.module.scss'

const SubpageHeaderBefore = () => {
  return (
    <div className={styles.headerImage}>
      <StaticImage
        src="../images/careers-logo.png"
        alt="Algbra Logo"
        layout="constrained"
        width={240}
        quality={100}
        placeholder="blurred"
        loading="eager"
      />
    </div>
  )
}

const SubpageHeaderAfter = () => {
  return (
    <div className={styles.headerButton}>
      <Permalink
        isBlank={true}
        isExternal={true}
        to={`https://algbra.jobs.personio.com/`}
      >
        <Button type="button" theme="primary" size="small">
          See open roles
        </Button>
      </Permalink>
    </div>
  )
}
export default function LayoutCareers({ pageContext, data }) {
  const zones = data.strapiGQL.career.contentZones
  return (
    <PageLayout>
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
        before={<SubpageHeaderBefore />}
        after={<SubpageHeaderAfter />}
      />
      {zones && <CareersZoneRenderer zones={zones} />}
    </PageLayout>
  )
}
export const pageQuery = graphql`
  query CareersQuery {
    strapiGQL {
      career {
        contentZones {
          __typename
          ... on StrapiGQL_ComponentZonesCareersBenefits {
            id
            title
            description
            benefits {
              id
              title
              description
              image {
                id
                url
                ext
                width
                height
                resolved {
                  publicURL
                }
              }
            }
          }
          ... on StrapiGQL_ComponentZonesCareersMission {
            id
            title
            description
            images {
              id
              url
              ext
              width
              height
              resolved {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 400
                    formats: WEBP
                    webpOptions: { quality: 100 }
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ... on StrapiGQL_ComponentZonesCareersFutureTeam {
            id
            title
            futureTeam {
              id
              title
              description
              image {
                id
                url
                ext
                width
                height
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 370
                      formats: WEBP
                      webpOptions: { quality: 100 }
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on StrapiGQL_ComponentZonesCareersJobs {
            id
            title
          }
        }
      }
    }
  }
`
