import Caption from '@algbra/ui/components/Caption/Caption'
import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { resolveAsAttachment } from '@algbra/ui/resolvers'
import { nl2br } from '@algbra/ui/text'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { mapIndexed } from 'rambdax'
import ContentBox from '../../components/ContentBox/ContentBox'
import styles from './CareersBenefits.module.scss'

export type Benefit = {
  id: string
  title: string
  description: string
  image: GatsbyResolvedAsset
}
export type ZoneCareersBenefitsProps = {
  __typename: 'StrapiGQL_ComponentZonesCareersBenefits'
  id: string
  title: string
  description: string
  benefits?: Benefit[]
}
export function ZoneCareersBenefits(props: ZoneCareersBenefitsProps) {
  const { title, description, benefits } = props
  return (
    <Whitespace type="md">
      <ContentBox size="small">
        <header className={styles.header}>
          <Heading level={2}>{title}</Heading>
          {description && (
            <Whitespace type="sm" as="div" hasAnimation={false}>
              <FreeText color="primary" innerHTML={description}>
                {description}
              </FreeText>
            </Whitespace>
          )}
        </header>
      </ContentBox>
      <Container>
        <Row className={styles.benefits}>
          {mapIndexed(
            ({ image, title, description }, index) => (
              <Column size={12} md={4} key={`${index}-${title}`}>
                <img
                  src={resolveAsAttachment(image)}
                  alt={title}
                  width={image.width}
                  height={image.height}
                  loading="lazy"
                />
                <div className={styles.content}>
                  <Caption type="lg" as="h3">
                    <span dangerouslySetInnerHTML={{ __html: nl2br(title) }} />
                  </Caption>
                  <FreeText
                    color="secondary"
                    innerHTML={description}
                    className={styles.description}
                  >
                    {description}
                  </FreeText>
                </div>
              </Column>
            ),
            benefits || []
          )}
        </Row>
      </Container>
    </Whitespace>
  )
}
