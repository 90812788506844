import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { VAlign } from '@algbra/ui/enums/align'
import { resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { mapIndexed } from 'rambdax'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './CareersMission.module.scss'

export type ZoneCareersMissionProps = {
  __typename: 'StrapiGQL_ComponentZonesCareersMission'
  id: string
  title: string
  description: string
  images: GatsbyResolvedAsset[]
}
export function ZoneCareersMission(props: ZoneCareersMissionProps) {
  const { title, description, images } = props
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true })
  const [isInView, setInView] = useState(false)

  useEffect(() => {
    if (inView) {
      setInView(true)
    }
  }, [inView])

  return (
    <Whitespace type="xl">
      <Container>
        <section id="mission" className={styles.container} ref={ref}>
          <Row>
            <Column size={12} md={7} className={styles.images}>
              <div className={styles.imagesContainer}>
                {mapIndexed(
                  (image, index) => (
                    <GatsbyImage
                      key={`mission-image-${index}`}
                      image={resolveAsImage(image)}
                      alt="Career Image"
                      className={classNames(styles.image, {
                        [styles[`image_${index}`]]: isInView,
                      })}
                    />
                  ),
                  images
                )}
              </div>
            </Column>
            <Column size={12} md={5}>
              <Heading level={2}>{title}</Heading>
              {description && (
                <Whitespace type="sm" as="div" hasAnimation={false}>
                  <FreeText color="primary" innerHTML={description}>
                    {description}
                  </FreeText>
                </Whitespace>
              )}
            </Column>
          </Row>
        </section>
      </Container>
    </Whitespace>
  )
}
