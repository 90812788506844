import Heading from '@algbra/ui/components/Heading/Heading'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import styles from './Job.module.scss'

export type JobProps = {
  title: string
  type: string
  location: string
  url: string
}
export function Job(props: JobProps) {
  const { title, type, location, url } = props

  return (
    <Permalink
      to={url}
      isExternal={true}
      isBlank={true}
      className={styles.job}
    >
      <Heading level={3} type="sm">
        {title}
      </Heading>
      <small className={styles.details}>
        {location} | {type}
      </small>
    </Permalink>
  )
}
