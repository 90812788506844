import React from 'react'
import { FieldError } from 'react-hook-form'
import styles from './ValidationError.module.scss'

const ValidationError = ({ message }: Partial<FieldError>) => (
  <span className={styles.message}>
    <span className={styles.innerMessage}>{message}</span>
  </span>
)

export default ValidationError
