import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './Label.module.scss'

export type LabelProps = {
  children: ReactNode
  className?: string
  inline?: boolean
}

function Label(props: LabelProps): JSX.Element {
  const { children, className, inline } = props
  return (
    <label
      className={classNames(
        styles.container,
        { [styles.inline]: inline },
        className
      )}
    >
      {children}
    </label>
  )
}

export default Label
