import FreeText from '@algbra/ui/components/FreeText/FreeText'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import { GatsbyImage } from 'gatsby-plugin-image'
import SwiperCore from 'swiper'
import { map } from 'rambdax'
import { useState } from 'react'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './CareersFutureTeam.module.scss'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'

export type FutureTeam = {
  id: string
  title: string
  description: string
  image: GatsbyResolvedAsset
}
export type ZoneCareersFutureTeamProps = {
  __typename: 'StrapiGQL_ComponentZonesCareersFutureTeam'
  id: string
  title: string
  futureTeam?: FutureTeam[]
}
export function ZoneCareersFutureTeam(props: ZoneCareersFutureTeamProps) {
  const { title, futureTeam } = props
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  return (
    <Whitespace type="md">
      <div className={styles.container}>
        <header className={styles.header}>
          <Heading level={2}>{title}</Heading>
        </header>
        <div className={styles.sliderContent}>
          <Swiper
            slidesPerView={'auto'}
            onSwiper={swiper => setSwiperRef(swiper)}
            freeMode={true}
            className={styles.slides}
            spaceBetween={30}
            navigation={true}
          >
            {map(
              ({ image, title, description }) => (
                <SwiperSlide className={styles.slide} key={image.id}>
                  <div className={styles.image}>
                    <GatsbyImage image={resolveAsImage(image)} alt={title} />
                  </div>
                  <div className={styles.content}>
                    <Heading type="sm" level={3}>
                      {title}
                    </Heading>
                    <FreeText
                      color="primary"
                      innerHTML={description}
                      className={styles.description}
                    >
                      {description}
                    </FreeText>
                  </div>
                </SwiperSlide>
              ),
              futureTeam || []
            )}
          </Swiper>
        </div>
        {futureTeam.length > 3 && (
          <div className={styles.navigation}>
            <button
              className={styles.prev}
              onClick={() => swiperRef.slidePrev()}
            >
              <Icon icon={getIconByName('chevron-left')} />
            </button>
            <button
              className={styles.next}
              onClick={() => swiperRef.slideNext()}
            >
              <Icon icon={getIconByName('chevron-right')} />
            </button>
          </div>
        )}
      </div>
    </Whitespace>
  )
}
